
import Vue from 'vue';
import utils from '../../util';

export default Vue.extend({
  name: 'app-drawer',
  props: ['drawerOffset'],
  computed: {
    adminUser(): object {
      return this.$store.state.customer.user;
    },
    allowAdvancedEditing(): boolean {
      try {
        if (utils.hasRight(this.adminUser, [])) return true;
      } catch (exp) {
        return false;
      }
      return false;
    },
    superUser(): boolean {
      return utils.hasRight(this.adminUser, ['SU']);
    },
    manageUsers(): boolean {
      try {
        if (utils.hasRight(this.adminUser, ['ACCESS_CONTROL_READ'])) return true;
      } catch (exp) {
        return false;
      }
      return false;
    },
  },
});
